import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate, Outlet, useNavigate } from "react-router-dom";

const Login = React.lazy(() => import('./components/Login/loginpage.jsx'))
const Layout = React.lazy(() => import('./components/Layout/layout.jsx'))
const CaseManagement = React.lazy(() => import('./components/CaseManagement/caseManagement.jsx'))
const InvestigatingDatabase = React.lazy(() => import('./components/InvestigatingDatabase/investigatingDatabase.jsx'))
const ExternalSources = React.lazy(() => import('./components/InvestigatingDatabase/ExternalSources/externalSources.jsx'))
const TBOMDatabases = React.lazy(() => import('./components/InvestigatingDatabase/TB-OMDatabases/TB-OMDatabases.jsx'))
// const DealersFarmersDb = React.lazy(() => import('./components/InvestigatingDatabase/TB-OMDatabases/DealersFarmers/dealersFarmers.jsx'))
// const TBOMDatabasesOffenders = React.lazy(() => import('./components/InvestigatingDatabase/TB-OMDatabases/offenders.jsx'))
const OffendersNetwork = React.lazy(() => import('./components/InvestigatingDatabase/TB-OMDatabases/Offenders/landingPage.jsx'))
// const OffendersList = React.lazy(() => import('./components/InvestigatingDatabase/TB-OMDatabases/OffendersList/index.jsx'))
const ECIComponent = React.lazy(() => import('./components/InvestigatingDatabase/ECI'))
const GSTComponent = React.lazy(() => import('./components/InvestigatingDatabase/GST/gst.jsx'))
const PPQSComponent = React.lazy(() => import('./components/InvestigatingDatabase/PPQS/ppqs.jsx'))
const DORISComponent = React.lazy(() => import('./components/InvestigatingDatabase/DORIS/doris.jsx'))
const RevenueDepartmentComponent = React.lazy(() => import('./components/InvestigatingDatabase/RevenueDepartment/index.jsx'))
const MCAComponent = React.lazy(() => import('./components/InvestigatingDatabase/MCA/main.jsx'))
const CIBILComponent = React.lazy(() => import('./components/InvestigatingDatabase/CIBIL/cibil.jsx'))
const ERComponent = React.lazy(() => import('./components/InvestigatingDatabase/ECIByNameAndLocation/eciByNameAndLocation.jsx'))
const Home = React.lazy(() => import('./components/Home/home.jsx'))
const CasesArchive = React.lazy(() => import("./components/CaseManagement/ArchivedCases/landingPage.jsx"))
const CaseSetupComponent = React.lazy(() => import("./components/CaseManagement/CaseSetup/add.jsx"))
const KeywordSearchComponent = React.lazy(() => import('./components/InvestigatingDatabase/KeywordSearch/main.jsx'))
const OpenCasesComponent = React.lazy(() => import('./components/CaseManagement/OpenCases/openCases.jsx'))
const CaseAdminComponent = React.lazy(() => import('./components/CaseManagement/CaseAdmin/landingPage.jsx'))
const CreateTemplate = React.lazy(() => import("./components/CaseManagement/CaseAdmin/Templates/createTemplate.jsx"))
const GeolocationComponent = React.lazy(() => import('./components/CaseManagement/GeolocationDashboard/index.jsx'))

const DailyUpdates = React.lazy(() => import('./components/CaseManagement/DailyUpdates/index.jsx'))

const Administration = React.lazy(() => import("./components/Administration/administration.jsx"))
const Scheduler = React.lazy(() => import("./components/Administration/Scheduler/index.jsx"))
const KeywordSearchMaster = React.lazy(() => import("./components/Administration/KeywordSearchMaster/index.jsx"))
const MailingMaster = React.lazy(() => import("./components/Administration/MailingMaster/index.jsx"))
const FileAccess = React.lazy(() => import("./components/Administration/FileAccess/index.jsx"))
const UserAnalytics = React.lazy(() => import("./components/Administration/UserAnalytics/index.jsx"))
const ForgotPassword = React.lazy(()=> import ('./components/Login/forgotpassword.jsx'))
const UserManagement = React.lazy(() => import('./components/UserManagement/index.jsx'))

const PreInvestigation = React.lazy(() => import("./components/PreInvestigation/preInvestigation.jsx"))
const ProposalManagement = React.lazy(() => import("./components/PreInvestigation/ProposalManagement/proposalManagement.jsx"))

//PWA
const LoginPWA = React.lazy(() => import('./components/PWA/Login/loginpage.jsx'))
const LayoutPWA = React.lazy(() => import('./components/PWA/LandingPage/layout.jsx'))
const HomePWA = React.lazy(() => import('./components/PWA/LandingPage/home.jsx'))
const ChatPWA = React.lazy(() => import('./components/PWA/Chat/index.jsx'))
const DailyUpdatesPWA = React.lazy(() => import('./components/PWA/DailyUpdates/index.jsx'))


const PrivateRoutes = () => {
  const auth = localStorage.getItem('loggedInUser')
  return (
    auth ? <Outlet /> : <Navigate to='/login' />
  )
}

const PrivateRoutesPWA = () => {
  const auth = localStorage.getItem('loggedInUser')
  return (
    auth ? <Outlet /> : <Navigate to='/pwa-login' />
  )
}

export default function Router() {

  return (
    <Suspense>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/pwa-login" element={<LoginPWA />} />
          <Route path="/reset-password" element={<ForgotPassword />} />



          <Route element={<PrivateRoutesPWA />}>
             <Route path="/homepwa/*" element={<LayoutPWA />} />
             <Route path="*" element={<Navigate to="/pwa-login" />} />
             <Route path="/pwa" element={<LayoutPWA />}>
                <Route path="daily-updates" element={<DailyUpdatesPWA />}/>
                <Route path="chat" element={<ChatPWA />}/>
             </Route>

          </Route>

          <Route element={<PrivateRoutes />}>
            <Route path="/home/*" element={<Layout />} />
            <Route path="/case-management" element={<CaseManagement />} />       
            <Route path="/app" element={<Layout />} >
              <Route index element={<Home />} />
              <Route path="/app/pre-investigation" element={<PreInvestigation />} />
              <Route path="/app/pre-investigation/proposal-management" element={<ProposalManagement />} exact />
              <Route path="/app/case-management" element={<CaseManagement />} />
              <Route path="/app/case-management/case-setup" element={<CaseSetupComponent />} />
              <Route path="/app/case-management/archive" element={<CasesArchive />} exact />
              <Route path="/app/case-management/open-cases" element={<OpenCasesComponent />} exact />
              <Route path="/app/case-management/case-admin" element={<CaseAdminComponent />} exact />
              <Route path="/app/case-management/geolocation" element={<GeolocationComponent />} exact />
              <Route path="case-management/case-admin/create-template" element={<CreateTemplate />} />
              <Route path="/app/case-management/daily-updates" element={<DailyUpdates />} />
              <Route path="/app/investigate/external" element={<ExternalSources />} exact />
              <Route path="/app/investigate" element={<InvestigatingDatabase />} />
              <Route path="/app/investigate/tb-om-databases" element={<TBOMDatabases />} exact />
              {/* <Route path="/app/investigate/tb-om-databases/dealersfarmers" element={<DealersFarmersDb />} exact /> */}
              {/* <Route path="/app/investigate/tb-om-databases/offenders" element={<TBOMDatabasesOffenders />} exact /> */}
              <Route path="/app/investigate/tb-om-databases/offenders-network" element={<OffendersNetwork />} exact />
              {/* <Route path="/app/investigate/tb-om-databases/offenders-list" element={<OffendersList />} exact /> */}
              <Route path="/app/investigate/external/eci" element={<ECIComponent />} exact />
              <Route path="/app/investigate/external/mca" element={<MCAComponent />} exact />
              <Route path="/app/investigate/external/cibil" element={<CIBILComponent />} exact />
              <Route path="/app/investigate/external/gst" element={<GSTComponent />} exact />
              <Route path="/app/investigate/external/ppqs" element={<PPQSComponent />} exact />
              <Route path="/app/investigate/external/doris" element={<DORISComponent />} exact />
              <Route path="/app/investigate/external/revenue-department" element={<RevenueDepartmentComponent />} exact/>
              <Route path="/app/investigate/external/eci-byname" element={<ERComponent />} exact />
              <Route path="/app/investigate/keyword-search" element={<KeywordSearchComponent />} exact />
              <Route path="/app/administration" element={<Administration />} />
              <Route path="/app/administration/keyword-search-master" element={<KeywordSearchMaster />} exact />
              <Route path="/app/administration/scheduler" element={<Scheduler />} exact />
              <Route path="/app/administration/mailing-master" element={<MailingMaster />} exact />
              <Route path="/app/administration/file-access" element={<FileAccess />} exact />
              <Route path="/app/administration/user-analytics" element={<UserAnalytics />} exact />
              <Route path="user-management" element={<UserManagement />} />
            </Route>
          </Route>

          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </BrowserRouter>
    </Suspense>

  );
}
